import { Component, ViewChild, OnInit, Input, ElementRef, EventEmitter, Output } from "@angular/core"
import { Router } from "@angular/router"
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"
import {
    Banner,
    Banners,
    BannerInstancia,
    BannerInstancias,
    BannerClick,
    BannerClicks,
    BannerImpresion,
    BannerImpresiones,
    BannerZona,
    BannerZonas,
    ImageBanner
} from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"

declare let canRunAds: boolean

@Component({
    selector: "banner-show",
    templateUrl: "banner_show.component.html",
    styleUrls: ["banner_show.component.scss"]
})
export class BannerShowComponent implements OnInit {
    @Input() pagina: string
    @Input() zona: string
    @Input() bannerTipo: string
    @ViewChild("container") containerElement: ElementRef
    @Output() load: EventEmitter<any> = new EventEmitter<any>()

    usuario_id: number = null
    banner: Banner
    bannerZona: BannerZona
    bannerInstancia: BannerInstancia

    target_url: string

    adBlockEnabled: boolean = false

    imagesBySize: ImageBanner[] = []
    currentImageSize: number = 300
    currentImageBanner: ImageBanner
    maxWidthByZone: string = "auto"
    isIframe: boolean = false
    isImage: boolean = false

    constructor(
        private authService: AuthService,
        private router: Router,
        private bannersService: Banners,
        private bannerClicksService: BannerClicks,
        private bannerImpresionesService: BannerImpresiones,
        private bannerInstanciasService: BannerInstancias,
        private bannerZonasService: BannerZonas,
        private domSanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        if (typeof canRunAds === "undefined" && this.bannerTipo !== "Imagen con link") {
            this.adBlockEnabled = true
        } else {
            let today = new Date().toISOString()
            let params: any = {
                zona: this.zona,
                pagina: this.pagina,
                banner_instancia: {
                    activo: 1
                },
                banner: {
                    lte: {
                        fecha_inicial: [today, "null"]
                    },
                    gte: {
                        fecha_final: [today, "null"]
                    }
                },
                include: "[banner_zona,banner:[banner_tipo,image_banners,iframe_banners]]"
            }

            let usuario = this.authService.getUserData()
            if (usuario) {
                this.usuario_id = usuario.id
            }

            var bannerObj: any = {
                banner: {}
                //from_preferencia_carreras: true
            }

            if (this.bannerTipo) {
                bannerObj.banner = { banner_tipo: { nombre: this.bannerTipo } }

                //params.banner.banner_tipo = { nombre: this.bannerTipo }
            }

            this.bannerInstanciasService.where(params).then((bannerInstancias: BannerInstancia[]) => {
                if (bannerInstancias && bannerInstancias.length > 0) {
                    if (bannerInstancias.length > 1) {
                        this.bannerInstancia = bannerInstancias[Math.floor(Math.random() * bannerInstancias.length)]
                    } else {
                        this.bannerInstancia = bannerInstancias[0]
                    }
                    this.banner = this.bannerInstancia.banner
                    this.bannerZona = this.bannerInstancia.banner_zona
                    this.isImage = this.banner && this.banner.banner_tipo.nombre == "Imagen con link"
                    this.isIframe = this.banner && this.banner.banner_tipo.nombre == "Iframe"
                    if (this.isImage && this.banner.image_banners)
                        this.target_url = this.banner.image_banners[0].link_url
                    if (this.isIframe && this.banner.iframe_banners)
                        this.target_url = this.banner.iframe_banners[0].iframe_url
                    if (this.isImage) this.setImageForSizes()
                }
            })

            /*this.bannersService.where(bannerObj).then((banners: Banner[]) => {
                if (banners && banners.length > 0) {
                    let banners_activos_ids = banners.filter(b => b.activo).map(b => b.id)
                    // var index: number = this.pickRandom(1,banners.length);
                    // this.bannerInstancia = banners[index-1];
                    // usamos arte_id en vez de banner_id para bypasear adblock
                    this.bannerInstanciasService
                        .where({
                            zona: this.zona,
                            pagina: this.pagina,
                            banner_instancia: { banner_id: banners_activos_ids }
                        })
                        .then((bannerInstancias: BannerInstancia[]) => {
                            if (bannerInstancias && bannerInstancias.length > 0) {
                                if (bannerInstancias.length > 1) {
                                    this.bannerInstancia =
                                        bannerInstancias[Math.floor(Math.random() * bannerInstancias.length)]
                                } else {
                                    this.bannerInstancia = bannerInstancias[0]
                                }
                                this.bannersService.find(this.bannerInstancia.banner_id).then((banner: Banner) => {
                                    var raw_url: string =
                                        banner.image_banners && banner.image_banners[0]
                                            ? banner.image_banners[0].link_url
                                            : banner.iframe_banners[0].iframe_url
                                    this.target_url = this.domSanitizer.bypassSecurityTrustResourceUrl(raw_url)
                                    this.bannerZonasService
                                        .find(this.bannerInstancia.banner_zona_id)
                                        .then((zona: BannerZona) => {
                                            this.bannerZona = zona
                                            this.banner = banner
                                            if (banner.image_banners) {
                                                this.setImageForSizes()
                                            }
                                            //this.viewed();
                                        })
                                })
                            }
                        })
                }
            })*/
        }
    }

    onLoad() {
        this.viewed()
        this.load.emit()
    }

    viewed() {
        let view = new BannerImpresion()
        view.usuario_id = this.usuario_id
        view.banner_instancia_id = this.bannerInstancia.id
        this.bannerImpresionesService.save(view).then(response => {})
    }

    clicked() {
        let click = new BannerClick()
        click.usuario_id = this.usuario_id
        click.banner_instancia_id = this.bannerInstancia.id
        this.bannerClicksService.save(click).then(response => {
            //Tal vez deberíamos tener otro método para ver si es un enlace externo o externo
            if (this.target_url && this.target_url.length > 0 && this.target_url.charAt(0) == "/") {
                this.router.navigate([this.target_url])
            } else {
                if (this.target_url) window.open(this.target_url, "_blank")
            }
        })
    }

    setImageForSizes() {
        this.banner.image_banners.forEach(ib => {
            if (!ib.size) {
                ib.size = this.bannerZona.ancho
            }
        })
        this.imagesBySize = this.banner.image_banners.sort((ib1, ib2) => ib1.size - ib2.size)
        this.maxWidthByZone = Math.min(this.bannerZona.ancho, this.imagesBySize[this.imagesBySize.length - 1].size) + ""
        this.currentImageSize = this.bannerZona.ancho
        this.setCurrentImageBannerBySize()
    }

    setCurrentImageBannerBySize() {
        if (this.isIframe || (this.imagesBySize && this.imagesBySize.length <= 1 && this.currentImageBanner)) return
        let sizeBreak = Math.min(this.currentImageSize, this.containerElement.nativeElement.offsetWidth)
        let ib = this.imagesBySize.filter(ib => ib.size > sizeBreak)
        this.currentImageBanner = ib && ib.length > 0 ? ib[0] : this.imagesBySize[this.imagesBySize.length - 1]
    }
}
