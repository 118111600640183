import { Component } from "@angular/core"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"
import { AppConfig, AuthService } from "@puntaje/shared/core"
import { Router } from "@angular/router"

/*#################################

Menú arriba del sitio.

#################################*/

declare const config: AppConfig

@Component({
    templateUrl: "base-menu-showcasebox.component.html",
    selector: "base-menu-showcasebox",
    styleUrls: ["base-menu-showcasebox.component.scss"]
})
export class BaseMenuShowcaseBoxComponent extends MenuComponent {
    itemsMenu: any[]
    menuItemsAlumnos = [
        {
            name: "Practica",
            route: "/evaluaciones/recomendadas",
            icon: "prueba"
        },
        {
            name: "Resultados",
            route: "/pruebas/resultados",
            icon: "resultados"
        },
        {
            name: "Planes de clase",
            route: "/plan_clases",
            icon: "planificacion-o"
        },
        {
            name: "Dudas del curso",
            route: "/dudas",
            icon: "dialogos"
        }
    ]

    menuItemsProfesores = [
        {
            name: "Evaluar",
            route: "/pruebas/generar_prueba",
            icon: "editar-prueba"
        },
        {
            name: "Corregir",
            route: "/evaluacion_instancias/subir",
            icon: "upload"
        },
        {
            name: "Resultados",
            route: "/evaluaciones/resultados",
            icon: "resultados"
        },
        {
            name: "Planes de clase",
            route: "/plan_clases",
            icon: "planificacion-o"
        },
        {
            name: "Mis noticias",
            route: "/noticias",
            icon: "megafono-o"
        }
    ]

    menuItemsByApp = {
        alumnos: this.menuItemsAlumnos,
        profesores: this.menuItemsProfesores
    }

    constructor(
        store: Store<State>,
        protected authService: AuthService,
        protected router: Router,
        public config: AppConfig
    ) {
        super(store)
        this.config = config
    }

    ngOnInit() {
        super.ngOnInit()

        if (this.config.app.navigationByApp && this.config.app.navigationByApp[this.config.app.name]) {
            this.itemsMenu = [...this.config.app.navigationByApp[this.config.app.name]]
        } else {
            this.itemsMenu = this.menuItemsByApp[this.config.app.name] || this.menuItemsAlumnos
        }

        if (this.authService.getEstablecimientos().length != 0 && config.app.name == "profesores") {
            if (this.itemsMenu.length > 0 && !this.itemsMenu.find(item => item.name == "Reportes")) {
                const item = {
                    name: "Reportes",
                    route: "/estadisticas/resumen_uso",
                    icon: "establecimiento-o"
                }
                this.itemsMenu.push(item)
            }
        }
    }
}
