<div class="contenedor">
    <!-- Login -->
    <div id="section1">
        <div class="caja caja-inicio">
            <div class="fading-image" [lazyLoad]="landingImage" [offset]="200"></div>
            <div class="caja-inner-margin" [ngClass]="{ 'no-logo': hideLogo }">
                <div class="landing-logo" *ngIf="!hideLogo">
                    <img
                        class="logo"
                        src="{{ logo }}"
                        alt="{{ config.plataforma.info.companyName }}"
                        title="{{ config.plataforma.info.companyName }}"
                    />
                </div>
                <h1 class="titulo" *ngIf="titulo">{{ titulo }}</h1>
                <ndc-dynamic
                    class="login"
                    [ndcDynamicComponent]="loginComponent"
                    [ndcDynamicInputs]="inputs"
                ></ndc-dynamic>
                <br />
            </div>
        </div>

        <ng-container *ngIf="useInternalFooter; else footer">
            <base-footer [disableNavigation]="true" [moveLogoToFirstColumn]="true"></base-footer>
        </ng-container>
        <ng-template #footer>
            <footer>
                <span>
                    <img
                        *ngIf="showLogo"
                        class="footer-logo"
                        src="{{ logoFooter }}"
                        alt="{{ config.plataforma.info.companyName }}"
                        title="{{ config.plataforma.info.companyName }}"
                    />
                    <!-- <ng-template *ngIf="true"> -->
                    <ng-container *ngFor="let logo of logosExtra">
                        <a href="{{ logo.link }}">
                            <img
                                class="footer-logo"
                                src="{{ logo.src }}"
                                alt="{{ logo.title }}"
                                title="{{ logo.title }}"
                            />
                        </a>
                    </ng-container>
                    <!-- </ng-template> -->
                </span>

                <div class="footer-col">
                    <h3 class="contactanos">Contáctanos</h3>
                    <ul>
                        <li>
                            <p>
                                <fa name="envelope-o"></fa>
                                &nbsp;{{ config.plataforma.info.contacto.mail }}
                            </p>
                        </li>
                    </ul>
                </div>
                <ng-template *ngIf="!logosExtrasLeft">
                    <ng-container *ngFor="let logo of logosExtra">
                        <a href="{{ logo.link }}">
                            <img
                                class="footer-logo"
                                src="{{ logo.src }}"
                                alt="{{ logo.title }}"
                                title="{{ logo.title }}"
                            />
                        </a>
                    </ng-container>
                </ng-template>
            </footer>
        </ng-template>
    </div>
</div>
