<cui-showcasebox [openStyle]="true">
    <cui-showcasebox-title-icon>
        <ogr-icon name="todos" class="icono"></ogr-icon>
    </cui-showcasebox-title-icon>
    <cui-showcasebox-title-text>¿Qué puedes hacer?</cui-showcasebox-title-text>
    <cui-showcasebox-content>
        <nav>
            <ul class="menu-options">
                <ng-container *ngFor="let item of itemsMenu">
                    <li routerLinkActive="active-menu">
                        <a [routerLink]="item.route" [queryParams]="item.params" role="button">
                            <ogr-icon *ngIf="!item.image" class="icono" name="{{ item.icon }}" align="top"></ogr-icon>
                            <img class="imagen" *ngIf="item.image" src="{{ item.image }}" />
                            <span class="texto">{{ item.name | t }}</span>
                            <fa name="caret-right" class="flecha"></fa>
                        </a>
                    </li>
                </ng-container>
            </ul>
        </nav>
    </cui-showcasebox-content>
</cui-showcasebox>
