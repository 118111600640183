<div *ngIf="!adBlockEnabled || isImage" #container (window:resize)="setCurrentImageBannerBySize()">
    <div *ngIf="target_url && isIframe">
        <iframe
            seamless="seamless"
            [attr.width]="bannerZona.ancho + 'px'"
            [attr.height]="bannerZona.alto + 'px'"
            [src]="target_url | trustedurl"
            (load)="onLoad()"
        ></iframe>
    </div>
    <div *ngIf="isImage && currentImageBanner">
        <img
            class="clickeable"
            [style.max-width.px]="maxWidthByZone"
            [attr.src]="currentImageBanner.image_url"
            [attr.width]="bannerZona.ancho + 'px'"
            [attr.height]="bannerZona.alto + 'px'"
            (click)="clicked()"
            (load)="onLoad()"
        />
    </div>
</div>
