import {
    ClasePlanClaseComponent,
    MisPlanClasesComponent,
    PlanClaseComponent,
    PlanClaseEditComponent,
    PlanClaseNewComponent,
    PlanClasesComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"

const routes: Routes = [
    {
        path: "plan_clases/new",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanClaseNewComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "plan_clases/:id/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanClaseEditComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "plan_clases/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanClaseComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "plan_clases/:id/sesiones/:sesionId",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ClasePlanClaseComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "plan_clases",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: MisPlanClasesComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "plan_clases_predefinidos",
        component: LoggedLayoutComponent,
        children: [
            { path: "", component: PlanClasesComponent, canActivate: [AuthGuard], data: { enableSiteWarning: false } }
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProfesoresPlanClasesRoutingModule {}
