import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from "@angular/core"
import { Notificacion, Notificaciones } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { NotificacionesPendingService } from "./notificaciones_pending.service"

@Component({
    selector: "notificaciones-showcasebox",
    templateUrl: "notificaciones_showcasebox.component.html",
    styleUrls: ["notificaciones_showcasebox.component.scss"]
})
export class NotificacionesShowcaseBoxComponent implements OnInit {
    notificaciones: Notificacion[]
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent
    @Input() maximoNotificaciones: number = 5
    @Input() pendientes: boolean = true
    @Input() enableContentStyle: boolean = true

    constructor(
        protected notificacionesService: Notificaciones,
        protected notificacionesPendingService: NotificacionesPendingService
    ) {}

    ngOnInit() {}
}
