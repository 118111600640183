<div class="artes_misticas" [ngClass]="{ no_background: noBackground }">
    <!--<div *ngIf="!isPro" class="add-margin" >
		<a [routerLink]="['/planes']">
			<img src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/puntaje/landing/pro.png">
		</a>
	</div>-->
    <ng-container *ngIf="!zona">
        <div class="add-margin">
            <banner-show
                class="margin-target"
                [pagina]="pagina"
                [zona]="'showcasebox0'"
                [bannerTipo]="'Imagen con link'"
            ></banner-show>
        </div>
        <div class="add-margin" *ngIf="usuarioId != 19561">
            <banner-show
                class="margin-target"
                [pagina]="pagina"
                [zona]="'showcasebox1'"
                [bannerTipo]="'Imagen con link'"
            ></banner-show>
        </div>
        <div class="add-margin" *ngIf="usuarioId == 19561">
            <banner-show
                class="margin-target"
                [pagina]="pagina"
                [zona]="'demo_showcasebox'"
                [bannerTipo]="'Imagen con link'"
            ></banner-show>
        </div>
        <div class="add-margin">
            <banner-show
                class="margin-target"
                [pagina]="pagina"
                [zona]="'showcasebox2'"
                [bannerTipo]="'Imagen con link'"
            ></banner-show>
        </div>
    </ng-container>
    <ng-container *ngIf="zona">
        <div class="add-margin">
            <banner-show
                class="margin-target"
                [pagina]="pagina"
                [zona]="zona"
                [bannerTipo]="'Imagen con link'"
            ></banner-show>
        </div>
    </ng-container>
</div>
