import { Component, Input, OnInit } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "generic-showcasebox",
    templateUrl: "./generic-showcasebox.component.html",
    styleUrls: ["./generic-showcasebox.component.scss"]
})
export class GenericShowcaseboxComponent implements OnInit {
    @Input() showcase: {
        nombre: string
        identificador: string
        titulo?: string
        columna?: number
        orden?: number
        inputs?: { [inputName: string]: any }
    }

    config = config

    constructor() {}

    ngOnInit() {}
}
