<ng-container [ngSwitch]="showcase.identificador">
    <ng-container *ngSwitchCase="'planes-estudios-showcasebox'">
        <planes-estudios-showcasebox></planes-estudios-showcasebox>
        <br />
    </ng-container>
    <ng-container *ngSwitchCase="'usuario-showcasebox'">
        <usuario-showcasebox
            [showUserDetails]="
                showcase.inputs?.showUserDetails != null && showcase.inputs?.showUserDetails != undefined
                    ? showcase.inputs.showUserDetails
                    : true
            "
        ></usuario-showcasebox>
        <br />
    </ng-container>
    <ng-container *ngSwitchCase="'notificaciones-showcasebox'">
        <notificaciones-showcasebox></notificaciones-showcasebox>
        <br />
    </ng-container>
    <ng-container *ngSwitchCase="'noticias-alumnos-showcasebox'">
        <noticias-alumnos-showcasebox></noticias-alumnos-showcasebox>
        <br />
    </ng-container>
    <ng-container *ngSwitchCase="'arte-home-showcasebox'">
        <arte-home-showcasebox [pagina]="'home'"></arte-home-showcasebox>
        <br />
    </ng-container>
    <ng-container *ngSwitchCase="'ensayos-mensuales'">
        <instrumento-mensual-showcasebox
            [tipoInstrumento]="config.plataforma.evaluacionDefault"
            [titulo]="showcase.titulo"
        ></instrumento-mensual-showcasebox>
        <br />
    </ng-container>
    <ng-container *ngSwitchCase="'noticias-showcasebox'">
        <noticias-showcasebox></noticias-showcasebox>
        <br />
    </ng-container>
    <ng-container *ngSwitchCase="'grupo-usuarios-profesor-showcase'">
        <grupo-usuarios-profesor-showcase></grupo-usuarios-profesor-showcase>
        <br />
    </ng-container>
    <ng-container *ngSwitchCase="'lista-establecimientos-profesor'">
        <lista-establecimientos-profesor></lista-establecimientos-profesor>
        <br />
    </ng-container>
    <ng-container *ngSwitchCase="'base-menu-showcasebox'">
        <base-menu-showcasebox></base-menu-showcasebox>
        <br />
    </ng-container>
    <ng-container *ngSwitchDefault>
        <br />
    </ng-container>
</ng-container>
