import { Component, OnInit, Input, Type } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { AuthService, I18nService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
import { LoginGraduatePilotoComponent, LoginStandardComponent } from "@puntaje/puntaje/new-modules/usuarios"
declare const config: AppConfig

@Component({
    selector: "base-landing",
    templateUrl: "base-landing.component.html",
    styleUrls: ["base-landing.component.scss"]
})
export class BaseLandingComponent implements OnInit {
    @Input() enableRegister = false
    @Input() useInternalFooter: boolean = false
    @Input() showLogo = true
    @Input() placeholder = undefined
    @Input() logosExtrasLeft = false
    @Input() hideLogo = false
    @Input() loginComponent: Type<any> = LoginStandardComponent

    config: typeof config
    landingImage: string = "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/shared/base/fondo_base.jpg"
    logo: string
    logoFooter: string
    titulo: string
    logosExtra: any[]

    inputs: any

    constructor(private router: Router, private route: ActivatedRoute, public authService: AuthService) {}

    ngOnInit() {
        this.inputs = {
            enableContactUs: false,
            enableRegister: this.enableRegister,
            enableFacebookLogin: false,
            enablePasswordRecovery: true,
            placeholder: this.placeholder
        }
        this.config = config
        if (this.authService.isLoggedIn()) {
            this.router.navigate(["home"])
        }

        this.landingImage = this.config.app.baseStyle?.landing?.backgroundImage
            ? this.config.app.baseStyle?.landing?.backgroundImage
            : "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/shared/base/fondo_base.jpg"

        this.titulo = this.config.app.baseStyle?.landing?.subtitle ? this.config.app.baseStyle?.landing?.subtitle : ""

        this.logo = this.config.app.baseStyle?.landing?.logo
            ? this.config.app.assets[this.config.app.baseStyle?.landing?.logo]
            : this.config.app.assets.logoLargeLight

        this.logoFooter = this.config.app.baseStyle?.landing?.logoFooter
            ? this.config.app.assets[this.config.app.baseStyle?.landing?.logoFooter]
            : this.config.app.assets.logoMediumLight

        this.logosExtra = this.config.app.baseStyle?.footer?.logosExtras
            ? this.config.app.baseStyle?.footer?.logosExtras
            : []
    }
}
