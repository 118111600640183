import { Component, ViewChild, OnInit, Input, HostBinding } from "@angular/core"
import { Router } from "@angular/router"
import {
    Asignatura,
    Asignaturas,
    TipoInstrumento,
    TipoInstrumentos,
    AsignaturaWithConfig
} from "@puntaje/nebulosa/api-services"
import {
    InstrumentoMensual,
    InstrumentoMensuales,
    EvaluacionInstancia,
    EvaluacionInstancias,
    GrupoRecurso,
    GrupoRecursos
} from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasByEvaluacionTipo, selectEvaluacionTipoAliases } from "@puntaje/puntaje/store"
import { Subscription } from "rxjs"
import { filter } from "rxjs/operators"

@Component({
    selector: "instrumento-mensual-showcasebox",
    templateUrl: "instrumento_mensual_showcasebox.component.html",
    styleUrls: ["instrumento_mensual_showcasebox.component.scss"]
})
export class InstrumentoMensualShowcaseboxComponent implements OnInit {
    @Input() tipoInstrumento: string
    @Input() showHardcodedPopup: boolean = false
    @Input() titulo: string = null

    config: typeof config
    asignaturas: AsignaturaWithConfig[]
    instrumentosMensualesByAsignaturaId: any
    evaluacionTipoAlias: string
    @HostBinding("class") innerClass = "showcasebox_style_1"

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    sub: Subscription
    subAlias: Subscription
    asignaturasByEvaluacionTipo$ = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )

    evaluacionTipoAliases$ = this.store.pipe(
        select(selectEvaluacionTipoAliases),
        filter(x => !!x)
    )

    constructor(
        private store: Store<State>,
        private instrumentoMensualService: InstrumentoMensuales,
        private tipoInstrumentoService: TipoInstrumentos,
        private evaluacionInstanciaService: EvaluacionInstancias,
        private grupoRecursosService: GrupoRecursos,
        private authService: AuthService,
        private router: Router,
        private simpleModalService: SimpleModalService
    ) {
        this.instrumentosMensualesByAsignaturaId = {}
        this.config = config
    }

    ngOnInit() {
        this.subAlias = this.evaluacionTipoAliases$.subscribe(evaluacionTipoAliases => {
            this.evaluacionTipoAlias = evaluacionTipoAliases[this.tipoInstrumento]
        })

        this.sub = this.asignaturasByEvaluacionTipo$.subscribe(async asignaturasByEvaluacionTipo => {
            this.setData(asignaturasByEvaluacionTipo)
        })
    }

    /**
     * Busca y setea los instrumentos mensuales por asignatura, las instancias de evaluación de esos instrumentos.
     * Los instrumentos mensuales a mostrar son los que tienen generador dentro de grupo_recurso Generadores Desafios Mensuales.
     * @param asignaturasByEvaluacionTipo Asignaturas agrupadas por tipo de evaluación
     * @returns void
     */
    async setData(asignaturasByEvaluacionTipo) {
        this.asignaturas = asignaturasByEvaluacionTipo[this.tipoInstrumento]

        const tipos: TipoInstrumento[] = await this.tipoInstrumentoService.where({
            tipo_instrumento: { tipo_instrumento: this.tipoInstrumento }
        })

        const date = new Date()
        const queryMensual = {
            instrumento_mensual: {
                month: date.getMonth(),
                year: date.getFullYear(),
                tipo_instrumento_id: tipos[0].id
            },
            with_generador_mensual: 1
        }

        const instrumentosMensuales: InstrumentoMensual[] = await this.instrumentoMensualService.where(queryMensual)

        const grupoRecursos = await this.grupoRecursosService.where({
            grupo_recurso: {
                recurso_type: "GeneradorInstrumento"
            },
            grupo_recurso_tipo: { grupo_recurso_tipo: "Generadores Desafíos Mensuales" },
            sort_by: "orden",
            order: "asc"
        })

        if (instrumentosMensuales.length > 0) {
            this.setInstrumentosMensualesByAsignaturaId(grupoRecursos, instrumentosMensuales)

            const evaluacionInstancias = await this.evaluacionInstanciaService.where({
                evaluacion_instancia: {
                    evaluacion_id: instrumentosMensuales.map(im => im.evaluacion_usuario.evaluacion_id),
                    usuario_id: this.authService.getUserData().id,
                    oficial: 1
                }
            })

            this.setInstanciaForInstrumentosMensuales(evaluacionInstancias)
        }

        this.loadingLayout.ready()
    }

    /**
     * Setea los instrumentos mensuales por asignatura
     * @param grupoRecursos Grupo de recursos de tipo GeneradorInstrumento para agrupar los generadores de los instrumentos mensuales
     * @param instrumentosMensuales Instrumentos mensuales del mes actual
     * @returns void
     */
    setInstrumentosMensualesByAsignaturaId(grupoRecursos, instrumentosMensuales) {
        this.asignaturas.forEach((asignatura: Asignatura) => {
            let generadorInstrumentos = asignatura.asignatura_plataformas[0].generador_instrumentos.filter(
                gi => gi.visible && gi.tipo_instrumento.tipo_instrumento == this.tipoInstrumento
            )
            const generadorInstrumentosIds = generadorInstrumentos.map(gi => gi.id)

            generadorInstrumentos = grupoRecursos
                .filter(grupoRecurso => generadorInstrumentosIds.includes(grupoRecurso.recurso_id))
                .map(grupoRecurso => generadorInstrumentos.find(gi => gi.id === grupoRecurso.recurso_id))

            generadorInstrumentos.forEach(gi => {
                const instrumentos = instrumentosMensuales.filter((e: InstrumentoMensual) => {
                    return e.asignatura_id === asignatura.id && e.instrumento.generador_instrumento_id == gi.id
                })
                if (instrumentos.length > 0) {
                    const instrumento = instrumentos.reduce((max, e) => {
                        const dateMax = new Date(max.created_at)
                        const dateE = new Date(e.created_at)
                        return dateMax > dateE ? max : e
                    })
                    if (!this.instrumentosMensualesByAsignaturaId[asignatura.id])
                        this.instrumentosMensualesByAsignaturaId[asignatura.id] = []
                    let im = { instrumento: instrumento, generador: gi }
                    this.instrumentosMensualesByAsignaturaId[asignatura.id].push(im)
                }
            })
        })
    }

    /**
     * Setea la instancia de evaluación para cada instrumento mensual
     * @param evaluacionInstancias Instancias de evaluación de los instrumentos mensuales
     * @returns void
     */
    setInstanciaForInstrumentosMensuales(evaluacionInstancias) {
        Object.keys(this.instrumentosMensualesByAsignaturaId).forEach(asignaturaId => {
            this.instrumentosMensualesByAsignaturaId[asignaturaId].forEach(instrumentoMensual => {
                const evaluacionInstancia = evaluacionInstancias.find(
                    ei => ei.evaluacion_id === instrumentoMensual.instrumento.evaluacion_usuario.evaluacion_id
                )
                if (evaluacionInstancia) {
                    instrumentoMensual["instancia"] = evaluacionInstancia
                }
            })
        })
    }
    // TODO: mover texto a traduciones
    goToPendingEvaluation(evaluacionId: number) {
        if (this.showHardcodedPopup) {
            this.simpleModalService.setModalHeader("Aviso")
            this.simpleModalService.setModalStringContent(
                `Estás por realizar uno de los Desafíos PAES mensuales*, al completarlo podrás participar por una beca de las 10 que se entregarán mensualmente en el programa Becas al Desafío 🤩 de nuestro preuniversitario online personalizado Puntaje Nacional Plus Intensivo en el plan +Class 30.
                🔔 Quédate atento a tu mail, nuestra plataforma y redes sociales donde mencionaremos a los beneficiarios del mes 🔔
                
                Términos y condiciones en la sección Noticias o Blog en Orientación.
                ¡Éxitos! 💙`
            )
            this.simpleModalService.setModalCallback(() => {
                const baseUrl =
                    config.evaluaciones[this.tipoInstrumento] && config.evaluaciones[this.tipoInstrumento].baseUrl
                        ? config.evaluaciones[this.tipoInstrumento].baseUrl
                        : "/ensayos"
                this.router.navigate([baseUrl + "/" + evaluacionId + "/realizar"])
            })

            this.simpleModalService.showSimpleModal()
        } else {
            const baseUrl =
                config.evaluaciones[this.tipoInstrumento] && config.evaluaciones[this.tipoInstrumento].baseUrl
                    ? config.evaluaciones[this.tipoInstrumento].baseUrl
                    : "/ensayos"

            this.router.navigate([baseUrl + "/" + evaluacionId + "/realizar"])
        }
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.sub.unsubscribe()
        this.subAlias.unsubscribe()
    }
}
