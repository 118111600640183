<div class="showcasebox">
    <div class="showcasebox_title">
        <div class="showcasebox_title_inner">
            <div class="showcasebox_title_icon">
                <div class="icon-container"><ogr-icon name="prueba" class="icon"></ogr-icon></div>
            </div>
            <div class="showcasebox_title_text">
                <!-- {{ tipoInstrumento | transformInstrumento: "plural" | capitalize }} mensuales -->
                {{
                    titulo ? titulo : "practicar.showcasebox.evaluaciones_mensuales.titulo"
                        | t: { evaluacionTipo: (evaluacionTipoAlias | capitalize) }
                }}
            </div>
        </div>
    </div>
    <div class="showcasebox_content">
        <div class="clearfix">
            <h4 class="title">
                <!-- {{ tipoInstrumento | transformInstrumento: "plural" | capitalize }} este mes -->
                {{
                    "practicar.showcasebox.evaluaciones_mensuales.este_mes"
                        | t: { evaluacionTipo: (evaluacionTipoAlias | capitalize) }
                }}
            </h4>
            <!-- <span class="last-results">
                Últimos
                <br />
                resultados

            </span> -->
            <span
                class="last-results"
                [innerHTML]="'practicar.showcasebox.evaluaciones_mensuales.ultimos_resultados' | t | trustedhtml"
            ></span>
        </div>
        <div class="contenido">
            <loading-layout #loadingLayout>
                <div class="lista">
                    <div *ngFor="let a of asignaturas">
                        <div
                            class="list-row"
                            *ngIf="
                                instrumentosMensualesByAsignaturaId[a.id] &&
                                instrumentosMensualesByAsignaturaId[a.id].length > 0
                            "
                        >
                            <div class="icono-cell {{ a.clase }}">
                                <ogr-icon name="{{ a.icono }}" class="ogricon"></ogr-icon>
                            </div>
                            <div class="cell">
                                <div class="inner-grid" *ngFor="let im of instrumentosMensualesByAsignaturaId[a.id]">
                                    <div class="grid-cell">
                                        <p
                                            class="customLabel"
                                            *ngIf="instrumentosMensualesByAsignaturaId[a.id].length > 1"
                                        >
                                            {{ im["generador"].generador_instrumento }}
                                        </p>
                                        <cui-button
                                            class="pending"
                                            [isBlock]="true"
                                            *ngIf="!im['instancia']"
                                            (click)="
                                                goToPendingEvaluation(
                                                    im['instrumento'].evaluacion_usuario.evaluacion_id
                                                )
                                            "
                                        >
                                            {{ "practicar.showcasebox.evaluaciones_mensuales.comenzar" | t }}
                                            <fa name="chevron-right" class="right-icon"></fa>
                                        </cui-button>
                                        <div class="ready" *ngIf="im['instancia']">
                                            <p>
                                                <span>¡Listo!</span>
                                                <ogr-icon name="ticket-circle"></ogr-icon>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="grid-cell result-grid-cell">
                                        <p class="por-definir" *ngIf="!im['instancia']">
                                            {{ "practicar.showcasebox.evaluaciones_mensuales.por_definir" | t }}
                                        </p>
                                        <p class="result {{ a.clase }}" *ngIf="im['instancia']">
                                            {{ im["instancia"].calificacion | number: "1.0-2" }}&nbsp;Pts.
                                        </p>
                                    </div>
                                </div>
                                <div class="inner-grid" *ngIf="!instrumentosMensualesByAsignaturaId[a.id]">
                                    <div class="grid-cell">
                                        <div class="unavailable">
                                            <p>
                                                {{ "practicar.showcasebox.evaluaciones_mensuales.no_disponible" | t }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="grid-cell result-grid-cell">
                                        <p class="por-definir">
                                            {{ "practicar.showcasebox.evaluaciones_mensuales.por_definir" | t }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!--<div class="pending-cell">
                                <ng-container *ngFor="let im of instrumentosMensuales[a.id]">
                                    <p class="customLabel" *ngIf="instrumentosMensuales[a.id].length > 1">{{im["generador"].generador_instrumento}}</p>
                                    <cui-button
                                        class="pending"
                                        [isBlock]="true"
                                        *ngIf="!im['instancia']"
                                        (click)="
                                            goToPendingEvaluation(
                                                im['instrumento'].evaluacion_usuario.evaluacion_id
                                            )
                                        "
                                    >
                                        {{ "practicar.showcasebox.evaluaciones_mensuales.comenzar" | t }}
                                        <fa name="chevron-right" class="right-icon"></fa>
                                    </cui-button>
                                    <div
                                        class="ready"
                                        *ngIf="im['instancia']"
                                    >
                                        <p>
                                            ¡Listo!
                                            <ogr-icon name="ticket-circle"></ogr-icon>
                                        </p>
                                    </div>
                                </ng-container>
                                <div class="unavailable" *ngIf="!instrumentosMensuales[a.id]">
                                    <p>{{ "practicar.showcasebox.evaluaciones_mensuales.no_disponible" | t }}</p>
                                </div>
                            </div>
                            <div class="result-cell">
                                <ng-container *ngFor="let im of instrumentosMensuales[a.id]">
                                        <p class="por-definir" *ngIf="!im['instancia']">
                                            {{ "practicar.showcasebox.evaluaciones_mensuales.por_definir" | t }}
                                        </p>
                                        <p class="result {{ a.clase }}" *ngIf="im['instancia']">
                                            {{
                                                im["instancia"].calificacion | number: "1.0-2"
                                            }}&nbsp;Pts.
                                        </p>
                                </ng-container>
                                <p class="por-definir" *ngIf="!instrumentosMensuales[a.id]">
                                    {{ "practicar.showcasebox.evaluaciones_mensuales.por_definir" | t }}
                                </p>
                            </div>-->
                        </div>
                    </div>
                </div>
            </loading-layout>
        </div>
        <div></div>
    </div>
</div>
