<cui-showcasebox [openStyle]="true" type="notificaciones">
    <cui-showcasebox-title-icon>
        <ogr-icon name="megafono"></ogr-icon>
    </cui-showcasebox-title-icon>
    <cui-showcasebox-title-text>{{ 'home.showcasebox.notificaciones.titulo' | t }}</cui-showcasebox-title-text>
    <cui-showcasebox-content>
        <notificaciones
            [pendientes]="pendientes"
            [maximoNotificaciones]="maximoNotificaciones"
            [enableMini]="true"
            [enableMaximo]="true"
            [maximoNotificaciones]="maximoNotificaciones"
        ></notificaciones>
    </cui-showcasebox-content>
    <cui-showcasebox-view-more>
        <a routerLink="/notificaciones">{{ 'home.showcasebox.notificaciones.ver_mas' | t }}</a>
    </cui-showcasebox-view-more>
</cui-showcasebox>
