import { Component, Input, ViewChild, ElementRef, AfterViewInit, OnInit, HostBinding } from "@angular/core"
import { SessionService } from "@puntaje/shared/core"

@Component({
    selector: "arte-home-showcasebox",
    templateUrl: "banners_showcasebox.component.html",
    styleUrls: ["banners_showcasebox.component.scss"]
})
export class BannersShowcaseBoxComponent implements OnInit {
    @Input() pagina: string
    @Input() zona: string
    @Input() noBackground: boolean
    @HostBinding("class") innerTheme = "showcasebox_style_1"
    isPro = false
    usuarioId: number
    constructor(private sessionService: SessionService) {}

    ngOnInit() {
        this.isPro = this.sessionService.isPro()
        this.usuarioId = this.sessionService.getUserData().id
    }
}
